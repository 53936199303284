<template>

  <div style="overflow: hidden; font-family: Arial, Helvetica, sans-serif">
    <div style="padding-left: 15%; padding-right: 15%; padding-top: 20px">
      <h2>
        Ik zet een veld uit
      </h2>
    </div>
    <hr class="solid">

    <VueSlickCarousel v-bind="settings" style="height 550px; overflow: hidden;">
        <div v-for="image in images" :key="image" class="img-wrapper">
            <img :src="image"/>
        </div>
    </VueSlickCarousel>

    <hr class="solid">

    <div class="row" style="padding-left: 15%; padding-right: 15%; overflow: hidden;">
        <div class="col">
            <iframe src="https://www.google.com/maps/d/embed?mid=1SDV2QUy-_fP6UBl5y82ZdSfV1bB-0jnn" style="height: 400px; width: 100%;"></iframe>
        </div>
        <div class="col">
            <p>
            In mijn zelfgemaakte camera groeit een plant. Hij is de resident in de ruimte waar het beeld ontstaat. Ik neem mijn camera mee naar het land, ik spreek met boeren en richt me op plekken waar dingen samenkomen, waar ze kruisen en niet enkel parallel lopen aan elkaar. Plekken waar in het boerenland interactie is en overlap plaatsvindt tussen twee gewassen. Dit wordt in de ecologie randeffect genoemd.
            </p>
            <p>
            Het groeiproces van de plant wordt onderdeel van het analoge beeld. Waar eerst enkel een hoopje aarde was, is de plant ontkiemd en inmiddels groeit hij bijna mijn camera uit. Een proces van loslaten en zorgdragen voor het beeld, een proces als een ritme van oorzaak en gevolg. Alles wat ik doe is gelieerd aan de vorige fase, de ene fase roept de vragen voor de volgende fase op.
            </p>
            <p>
            In de landbouw worden vlaggen gebruikt om op een diervriendelijke manier gewas te beschermen, zo vertelde een boer mij. Ik heb vervolgens dertig blauwe vlaggen gemaakt, bedrukt met beelden van de plant in de camera. Deze vlaggen heb ik gestuurd naar mensen verspreid over Nederland, nadat ik eerder naar diezelfde mensen een zaadje had toegezonden: ‘ik vraag u de vlag te plaatsen bij het gewas en hiervan een foto te maken.’
            </p>
            <p>
            Ik zet het veld uit. Ik word boer.
            </p>
        </div>

    </div>

    <hr class="solid">
    <div style="padding-left: 15%; padding-right: 15%; padding-bottom: 20px; overflow: hidden; text-align: center">
      <h5>
        Mag ik het veld ook bij jou uitzetten? Mail naar abelschaminee[apenstaartje]gmail[punt]com.
      </h5>
    </div>
  </div>

</template>

<script>
  import Vue from 'vue'
  import { BootstrapVue } from 'bootstrap-vue'

  // Import Bootstrap an BootstrapVue CSS files (order is important)
  import 'bootstrap/dist/css/bootstrap.css'
  /*import 'bootstrap-vue/dist/bootstrap-vue.css'*/

  // Make BootstrapVue available throughout your project
  Vue.use(BootstrapVue)
  // Optionally install the BootstrapVue icon components plugin
  /*Vue.use(IconsPlugin)*/

  import VueSlickCarousel from 'vue-slick-carousel'
  /*import './vue-slick-carousel-theme.css'*/

  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

  export default {
    name: 'MyComponent',
    components: { VueSlickCarousel },
    data: function() {
        return {
            name: '',
            settings: {
                /*"dots": true,*/
                "infinite": true,
                "centerMode": true,
                /*"centerPadding": "20px",*/
                /*"slidesToShow": 5,*/
                "slidesToScroll": 1,
                "variableWidth": true,
                "speed": 4000,
                "autoplay": true,
                "autoplaySpeed": 4000,
                "focusOnSelect": true,
                "touchThreshold": 5,
            },
            images: [
                "beeld10.jpg",
                "beeld11.jpg",
                "beeld12.jpg",
                "beeld13.jpg",
                "beeld14.jpg",
                "beeld15.jpg",
                "beeld16.jpg",
                "beeld17.jpg",
                "beeld18.jpg",
                "beeld19.jpg",
                "beeld1.jpg",
                "beeld20.jpg",
                "beeld21.jpg",
                "beeld22.jpg",
                "beeld24.jpg",
                "beeld25.jpg",
                "beeld26.jpg",
                "beeld27.jpg",
                "beeld28.jpg",
                "beeld2.png",
                "beeld3.jpg",
                "beeld4.jpg",
                "beeld5.jpg",
                "beeld8.jpg",
                "beeld9.jpg",
                "beeld6.jpg",
                "beeld7.jpg",
                "beeld23.jpg"
            ],
        }
    }
  }
</script>

<style>
.carousel-wrapper {
  padding: 40px;
}
.img-wrapper img {
  margin: auto;
  width: auto;
  height: 500px;
  /*background-image: linear-gradient(gray 100%, transparent 0);*/
  padding-left: 5px;
}

hr.solid {
    border-top: 1px solid black;
}

.slider_img {
    max-height: 500px;
    width: auto;
}
</style>
